import { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { Icon } from "@iconify/react";

import SharpeLogoWhite from "../images/sharpe-logo-white.svg";
import SharpeLogoBlack from "../images/sharpe-logo-black.svg";

import { getSharpePointsForUser } from "../firebase/config";
import ShimmerButton from "./magicui/shimmer-button";
import { ChevronDownIcon } from "lucide-react";
// import { useRouter } from 'next/router'
import GlobalSearch from "./GlobalSearch";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { NavMenu } from "./NavMenu";

// const CustomTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme, mode }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     color: mode === "light" ? "#000" : "#98ECFF",
//     border: "1px solid #98ECFF",
//     background: mode === "light" ? "#98ECFF" : "transparent",
//     padding: "8px 16px",
//     fontSize: "14px",
//     cursor: "default",
//     fontFamily: "Inter, sans-serif",
//     "&:hover": {
//       border: "1px solid #98ECFF",
//     },
//     textTransform: "capitalize",
//     borderRadius: "0",
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     color: mode === "light" ? "#98ECFF" : "#98ECFF",
//   },
// }));

export default function Header({ onModeChange, walletAddress }) {
  const [mode, setMode] = useState("dark");
  const [sharpePoints, setSharpePoints] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  // const isSmallScreen = useIsSmallScreen(600)
  const mainWebsiteApiUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PUBLIC_API_URL
      : process.env.REACT_APP_PUBLIC_LOCAL_API_URL;

  const handleSearchInputClick = () => {
    setOpenSearch(true);
  };

  // Function to close search (received from child)
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };

  console.log("walletAddress", walletAddress);
  // Function to toggle mode
  const toggleMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
    onModeChange(newMode);
  };

  useEffect(() => {
    const fetchSharpePoints = async () => {
      try {
        const points = await getSharpePointsForUser(walletAddress);
        console.log("points", points);
        setSharpePoints(points);
      } catch (error) {
        console.error("Error fetching Sharpe points:", error);
      }
    };

    if (walletAddress) {
      fetchSharpePoints();
    }
  }, [walletAddress]);

  const renderIcon = () => {
    if (mode === "light") {
      return (
        <Icon
          icon="tdesign:mode-dark"
          width="22"
          height="22"
          style={{ color: "rgb(74 74 74)" }}
        />
      );
    } else {
      return (
        <Icon
          icon="iconamoon:mode-light"
          width="22"
          height="22"
          style={{ color: "#cfcfcf" }}
        />
      );
    }
  };
  const icon = renderIcon();

  const handleKeydown = useCallback(
    (event) => {
      // ** Shortcut keys to open searchbox (Ctrl + /)
      if (!openSearch && event.which === 191 && (event.ctrlKey || event.metaKey)) {
        setOpenSearch(true);
      }
    },
    [openSearch]
  );

  const handleKeyUp = useCallback(
    (event) => {
      // ** ESC key to close searchbox
      if (openSearch && event.keyCode === 27) {
        setOpenSearch(false);
      }
    },
    [openSearch]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp, handleKeydown]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        margin: "4px 0px",
        justifyItems: "center",
      }}
    >
      <div className="flex items-center ">
        <a href="https://sharpe.ai/" style={{ textDecoration: "none", margin: "10px 20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              height={35}
              width={110}
              src={mode === "light" ? SharpeLogoBlack : SharpeLogoWhite}
              alt="profile"
            />
          </Box>
        </a>
        <div
          class="hidden lg:flex max-w-md mx-auto"
          onClick={handleSearchInputClick}
        >
          <div class="relative flex items-center cursor-pointer border border-[#27272a] px-2 py-0 w-full h-10 rounded-[8px] focus-within:shadow-lg bg-transparent overflow-hidden">
            <div className="flex items-center">
              <div class="grid place-items-center h-full w-12 text-neutral-300">
                <Icon
                  icon="fluent:search-16-regular"
                  width="22"
                  height="22"
                  style={{ color: "#fff", cursor: "pointer" }}
                />
              </div>
              <span class="peer h-full w-full outline-none bg-transparent text-sm text-neutral-400 pr-2 pl-1">
                Search
              </span>
              <p className="text-sm flex items-center gap-1 text-neutral-500">
                Press{" "}
                <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded  bg-[#27272A] px-1.5 font-mono text-[10px] font-medium text-neutral-300 opacity-100">
                  <span className="text-md">⌘</span>/
                </kbd>
              </p>
            </div>
          </div>
        </div>
      </div>
      <NavMenu
        apiUrl={mainWebsiteApiUrl}
        customClasses={{
          container: "custom-container-class",
          navSection: {
            button: "custom-button-class",
          },
        }}
      />

      <div style={{ display: "flex", gap: "8px", margin: "10px 20px" }}>
        {/* <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild className="bg-transparent rounded-md">
              <Button
                variant="outline"
                size="icon"
                className={`
        bg-transparent 
        rounded-[6px] 
        h-[44px]
        w-[44px]
        hover:bg-transparent 
        ${mode === "dark" ? "border-neutral-700" : "border-neutral-400"} 
        ${mode === "dark" ? "dark:bg-black" : ""}
      `}
                onClick={toggleMode}
              >
                {icon}
              </Button>
            </TooltipTrigger>
            <TooltipContent
              className={`border-neutral-800 rounded-[4px] bg-transparent ${
                mode === "dark" ? "border-neutral-800" : "border-neutral-200"
              } ${mode === "dark" ? "text-neutral-200" : "text-black"}`}
            >
              <p
                className={`text-[12px]
               ${mode === "dark" ? "text-white" : "text-black"},`}
              >
                Switch to {mode === "light" ? "dark" : "light"} mode
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider> */}
        <Box sx={{ display: "flex", gap: "0px" }}>
          <div className="z-10 flex items-center justify-center">
            <ShimmerButton
              className="shadow-2xl"
              onClick={() => window.open("https://stake.sharpe.ai/", "_self")}
            >
              <span className="whitespace-pre-wrap text-center py-0 text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-[14px]">
                Earn $SAI
              </span>
            </ShimmerButton>
          </div>
          {/* <TooltipProvider delayDuration={200}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className={`
        bg-transparent 
        rounded-[6px] 
        h-[44px]
        w-[144px]
        font-normal
        hover:bg-transparent 
        
        ${mode === "dark" ? "border-neutral-700" : "border-neutral-400"} 
        ${mode === "dark" ? "text-neutral-50" : "text-neutral-900"} 
        ${mode === "dark" ? "hover:text-neutral-50" : "hover:text-neutral-900"} 
        ${mode === "dark" ? "dark:bg-transparent" : ""}
      `}
                >
                  {Number(sharpePoints) === 0
                    ? "--"
                    : `${numeral(Number(sharpePoints)).format("0.00a")}`}
                  &nbsp;
                  <span>PTS</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent
                className={`border-neutral-800 mr-4 rounded-[4px] bg-transparent ${
                  mode === "dark" ? "border-neutral-800" : "border-neutral-200"
                } ${mode === "dark" ? "text-neutral-200" : "text-black"}`}
                side="bottom"
              >
                <p
                  className={`text-[12px] w-[260px] text-center leading-[15px]
               ${mode === "dark" ? "text-white" : "text-black"},`}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      width: "260px",
                      textAlign: "center",
                      lineHeight: "16px",
                      color: mode === "dark" ? "text-white" : "text-black",
                    }}
                  >
                    Points are updated weekly at the end of each EPOCH. Here's
                    the{" "}
                    <a
                      href="https://x.com/SharpeLabs/status/1807469335604351416"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underLine" }}
                    >
                      Announcement
                    </a>
                    .
                  </p>
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider> */}
        </Box>
      </div>
      <GlobalSearch openSearch={openSearch} handleClose={handleCloseSearch} />
    </div>
  );
}
